<template>
  <Head>
    <Meta charset="utf-8" />
    <Meta name="viewport" content="width=device-width, initial-scale=1" />
    <Link rel="preconnect" href="https://fonts.googleapis.com" />
    <Link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="anonymous" />
    <Link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    />
    <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <Link rel="manifest" href="/site.webmanifest" />
    <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <Meta name="msapplication-TileColor" content="#da532c" />
    <Meta name="theme-color" content="#ffffff" />
  </Head>

  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import AOS from 'aos'
import useMq from '@/composables/useMq'

const { SITE_URL } = useRuntimeConfig()
useServerSeoMeta({
  ogImage: `${SITE_URL}/images/og-image.png`,
  ogImageSecureUrl: `${SITE_URL}/images/og-image.png`,
  ogImageWidth: '1200',
  ogImageHeight: '630',
  ogImageAlt: 'Medical Licensing promo img',
  ogImageType: 'image/png',
  ogLocale: 'en_US',
  ogSiteName: 'Credsy',
  ogType: 'website',
  ogUrl: `${SITE_URL}/`,
  twitterImage: `${SITE_URL}/images/og-image.png`,
  twitterImageAlt: 'Medical Licensing promo img',
  twitterCard: 'summary_large_image',
})

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  bodyAttrs: {
    class: 'bg-white',
  },
  meta: [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width, initial-scale=1' }],
})

useSchemaOrg([
  defineWebSite({}),
  defineWebPage({}),
  defineOrganization({
    name: 'Medicallicensing',
    logo: `/images/logo.mark.png`,
  }),
])

const {
  screen,
  isPhone,
  isShortTablet,
  isWideTablet,
  isTablet,
  isDesktop,
  isPhoneOrTablet,
  isTabletOrDesktop,
  isXs,
  isSm,
  isMd,
  isAir,
  isLg,
  isXl,
  is2Xl,
} = useMq()

provide('screen', screen)
provide('isPhone', isPhone)
provide('isShortTablet', isShortTablet)
provide('isWideTablet', isWideTablet)
provide('isTablet', isTablet)
provide('isDesktop', isDesktop)
provide('isPhoneOrTablet', isPhoneOrTablet)
provide('isTabletOrDesktop', isTabletOrDesktop)
provide('isXs', isXs)
provide('isSm', isSm)
provide('isMd', isMd)
provide('isAir', isAir)
provide('isLg', isLg)
provide('isXl', isXl)
provide('is2Xl', is2Xl)

onMounted(() => {
  AOS.init()
})
</script>
